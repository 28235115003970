



















import { Component, Prop, Vue } from "vue-property-decorator";
import { CAlert } from "@/models/utils/c-alert.enum";

@Component
export default class CosmopolStatusSelect extends Vue {
  @Prop({ required: true, type: String })
  private type: CAlert;

  get iconName(): string {
    switch (this.type) {
      case CAlert.SUCCESS:
        return "check-circle";
      case CAlert.WARNING:
        return "warning";
      default:
        return "close-circle";
    }
  }
}
